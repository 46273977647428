.main {
  width: 100%;
}

.bgGradient {
  width: 100%;
}

@media (min-width: 1280px) {
  .main {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .rightPartWrapper {
    width: 375px;
    margin-left: 30px;
  }
}
