.specListWrapper {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  justify-content: center;
}

.title {
  margin-bottom: 20px;
  font-size: 16px;
  text-align: center;
}
.speciality {
  padding: 6px 12px;
  margin: 0px 10px 10px 0px;
  background-color: var(--color-primary-white);
  border-radius: 20px;
  font-size: 14px;
}

.btn {
  background: var(--color-primary-20);
  color: var(--color-primary-100);
  cursor: pointer;
}

@media (min-width: 768px) {
  .title {
    margin-bottom: 40px;
  }
}
