.courseMainSights {
  padding: var(--property-card-margin-mobile);
  margin: 0 var(--property-mobile-side-padding) 60px;
  background: var(--color-lavender-20);
  border-radius: var(--property-border-radius);
  box-shadow: 0px 4px 30px #4a71a41a;
}

.mainSightsWrapper {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: var(--color-base-black);
  font-size: 16px;
  line-height: 22px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.image {
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.lectursWrapper {
  margin-top: 30px;

  .scroll :global {
    .simplebar-track.simplebar-horizontal {
      .simplebar-scrollbar::before {
        height: 0;
      }
    }
  }
}

.lectur {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: -38px;
  margin-bottom: 10px;
}

.avatar {
  width: 142px;
  height: 142px;
  box-sizing: content-box;
  border: 13px solid #f8f8f8;
  margin-bottom: 6px;
}

.name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.info {
  color: var(--color-base-80);
  font-size: 12px;
  line-height: 17px;
}

.aboutLecturs {
  position: relative;
  display: flex;
  min-height: 148px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  padding-top: 33px;
  border: 1px solid var(--color-base-20);
  margin-top: 50px;
  background: var(--color-primary-white) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
}

.icon {
  position: absolute;
  top: -20px;
}

@media (min-width: 768px) {
  .item {
    align-items: center;
  }

  .courseMainSights {
    margin: 0 0 60px;
  }

  .scroll {
    .scrollWrapper {
      display: flex;
      padding: 0;
    }
  }
}

@media (min-width: 1280px) {
  .courseMainSights {
    display: flex;
    padding: 30px 50px;
    margin: 0 0 120px;
  }

  .item {
    display: block;
    margin-bottom: 0;
    margin-left: 80px;
    font-size: 18px;
    line-height: 24px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  .image {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
  }

  .lectur {
    margin-right: -67px;
  }

  .avatar {
    width: 238px;
    height: 238px;
    border: 20px solid #f8f8f8;
  }

  .name {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 27px;
  }

  .info {
    font-size: 16px;
    line-height: 22px;
  }

  .scroll {
    .scrollWrapper {
      margin-top: 15px;
    }
  }

  .aboutLectursWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .aboutLecturs {
    width: 350px;
    padding: 30px;
    padding-top: 43px;
    margin-top: 60px;
  }

  .mainSightsWrapper {
    flex-direction: row;
  }
}
