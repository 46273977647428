.wrapper {
  padding: 30px 0 0;
  background-color: #e1dbcb;
}

.specialitiesWrapper {
  padding: 30px 20px;
}

@media (min-width: 768px) {
  .wrapper {
    padding-top: 80px;
  }

  .specialitiesWrapper {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
