.cardsWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.cardTitle {
  font-size: 40px;
  font-weight: 500;
}

.cardSubtitle {
  max-width: 314px;
  font-size: 18px;
  font-weight: 500;
}

.card1 {
  position: relative;
  min-width: 255px;
  height: 225px;
  padding: 20px;
  background-color: var(--color-primary-60);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 162px 70px;
  border-radius: 20px;
}
.card2 {
  position: relative;
  display: block;
  min-width: 255px;
  height: 225px;
  padding: 20px;
  background-color: #535353;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 137px 67px;
  border-radius: 20px;
  color: var(--color-primary-white);
}
.card3 {
  position: relative;
  min-width: 255px;
  height: 225px;
  padding: 20px;
  background-color: #e1dbcb;
  background-position: bottom left;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 128px 54px;
  border-radius: 20px;
}
.cardLink {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  background-color: var(--color-primary-white);
  background-image: url('../../../public/img/intro-alt/arrow-link.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40% 40%;
  border-radius: 50%;
  cursor: pointer;
}
@media (min-width: 375px) {
  .card1 {
    height: 200px;
  }
}

@media (min-width: 768px) {
  .cardTitle {
    font-size: 60px;
  }

  .card1 {
    height: 225px;
  }
  .card2 {
    height: 225px;
  }
  .card3 {
    height: 225px;
  }
}

@media (min-width: 1280px) {
  .cardsWrap {
    min-height: 230px;
    flex-direction: row;
    gap: 30px;
  }
  .card1 {
    width: 540px;
    height: auto;
  }
  .card2 {
    width: 255px;
    height: auto;
  }
  .card3 {
    width: 255px;
    height: auto;
  }
}
