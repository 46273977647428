.block {
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid var(--color-base-20);
  background: var(--color-primary-white);
  border-radius: 20px;
}

.name {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.speciality {
  margin-bottom: 20px;
  color: var(--color-base-80);
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
}

.review {
  line-height: 18px;
}

@media (min-width: 768px) {
  .block {
    padding: 30px;
  }
}
