.sectionWrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
}

.title {
  width: 100%;
  padding-top: 40px;
  font-size: 42px;
  font-weight: 500;
  line-height: 64px;
  text-align: center;
}

.outlined {
  padding: 0px 20px;
  background: #e1dbcb;
  border-radius: 200px;
}

.subtitle {
  display: block;
  margin: 30px auto;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.btnWrap {
  max-width: 100%;
}
@media (min-width: 375px) {
}

@media (min-width: 768px) {
  .title {
    font-size: 72px;
    line-height: 100px;
  }
  .subtitle {
    display: block;
    max-width: 640px;
    font-size: 20px;
  }
  .btnWrap {
    max-width: 199px;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .subtitle {
    font-size: 22px;
  }
}

@media (min-width: 1280px) {
  .title {
    padding-top: 100px;
    font-size: 82px;
    line-height: 124px;
  }

  .outlined {
    padding: 5px 30px;
  }
}
