.crowdHelpCards {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 auto;
  gap: 20px;
}

.crowdHelpCard {
  height: 100%;
  padding: 20px;
  border: 1px solid var(--color-base-20);
  margin: 0 auto;
  background-color: var(--color-primary-white);
  border-radius: 20px;
}

.link {
  min-width: 100%;
}

.noLink {
  min-width: 100%;
  pointer-events: none;
}

.title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.description {
  font-size: 14px;
  text-align: center;
}

.imageWrapper {
  width: 100%;
}

.image {
  display: block;
  width: 100%;
  min-height: 180px;
  max-height: 180px;
  margin: 0 auto 20px;
  border-radius: 14px;
  object-fit: cover;
}

.scrollWrap {
  width: 100%;

  :global {
    .simplebar-wrapper {
      min-height: 420px;
    }

    .simplebar-track.simplebar-horizontal {
      height: 6px;
      margin: 0;
    }

    .simplebar-track {
      overflow: initial;
      border-bottom: 2px solid #d9d9d9;
      border-radius: 0px;
    }

    .simplebar-scrollbar {
      top: 4px !important;
      height: 4px !important;
    }
  }
}

@media (min-width: 480px) {
  .image {
    width: 100%;
    height: initial;
  }
}

@media (min-width: 576px) {
  .description {
    font-size: 16px;
  }

  .crowdHelpCards {
    width: 330px;
  }

  .crowdHelpCard {
    width: 100%;
    max-width: 350px;
    height: 100%;
  }
}

@media (min-width: 1280px) {
  .crowdHelpCards {
    display: flex;
    width: unset;
    flex-direction: row;
    align-items: stretch;
    gap: 30px;
  }

  .image {
    min-height: 200px;
    max-height: 200px;
  }

  .crowdHelpCard {
    height: 522px;
    max-height: auto;
  }

  .link {
    min-width: auto;
  }

  .noLink {
    min-width: auto;
  }

  .scrollWrap {
    :global {
      .simplebar-wrapper {
        min-height: 500px;
      }
    }
  }
}