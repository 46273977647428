.sectionBG {
  background-color: var(--intro-alt-bg);
}

.title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}
.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.card {
  max-width: 100%;
}
.modificator {
  padding-right: 10px;
}

@media (min-width: 768px) {
  .title {
    margin-bottom: 60px;
    font-size: 34px;
  }
}

@media (min-width: 1280px) {
  .title {
    font-size: 58px;
  }
  .card {
    max-width: 350px;
  }
  .cardWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
