.content {
  width: 100%;
  overflow-x: hidden;
}

.amoWidget {
  max-width: 350px;
}



.imgWrapper {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 218px;
}

.imgBack {
  display: none;
  max-height: 313px;
}

.imgBackMob {
  position: absolute;
  display: block;
  width: 150%;
  height: 100%;
  max-height: 218px;
}

.helperCourse {
  padding-top: 25px;
}

.bgWhite {
  background-color: #fff;
}

.courseHeader {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 var(--property-mobile-side-padding);
}

.headerWrapper {
  padding-bottom: 60px;
}

.headerBtns {
  display: block;

  button:nth-child(1) {
    margin-bottom: 20px;
  }
}

.titleHeader {
  margin: 40px 0 30px;
  font-size: 32px;
  font-weight: var(--property-h1-weight);
}

.descriptionContainer {
  display: flex;
}

.descriptionIcon {
  flex-shrink: 0;
  padding: 5px 0;
  margin-right: 16px;
}

.descriptionHeader {
  margin: 0 0 40px;
  font-size: 16px;
  line-height: 22px;
}

.cardsWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.filtersModificator {
  flex-direction: column;
}

.switchToggleWrapper {
  margin: 0 15px 25px;
}

.noCourseCards {
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}

.sectionHeader {
  padding: 0 15px;
  font-size: 28px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
}

.container {
  padding: 0 var(--property-mobile-side-padding);
}

.startContainer {
  margin-top: 60px;
  margin-bottom: 30px;
}

.popupShown {
  animation: fadeIn 0.5s ease-in-out;
}

.popupNotShown {
  animation: fadeOut 0.5s ease-in-out;
}

@media (min-width: 768px) {
  .popupConsult {
    max-width: 500px;
  }

  .popupNotShown {
    animation: inherit;
  }

  .helperCourse {
    padding-top: 60px;
  }

  .popupShown {
    animation: inherit;
  }

  .courseHeader {
    padding: 0;
  }

  .container {
    padding: 0;
  }

  .descriptionContainer {
    align-items: center;
  }

  .descriptionHeader {
    margin-bottom: 0;
  }

  .headerWrapper {
    padding-bottom: 80px;
  }

  .headerBtns {
    display: flex;
    max-width: 200px;
    margin-top: 50px;

    button:first-child {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }

  .cardsList {
    display: block;
  }

  .txtWrapper {
    margin: 0;
  }

  .filtersModificator {
    flex-direction: row;
  }

  .switchToggleWrapper {
    margin: var(--property-switch-toggle-margin);
  }

  .titleHeader {
    font: var(--property-title-1);
  }

  .sectionHeader {
    padding: 0;
    font-size: 34px;
    line-height: 52px;
  }

  .pageContainer {
    .cardWrapper {
      width: 320px;
      flex-shrink: 0;
      margin-right: 20px;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:nth-of-type(3n) {
        margin-right: 20px;
      }
    }
  }
}

@media (min-width: 1280px) {
  .headerWrapper {
    display: flex;
    align-items: flex-start;
    padding-top: 75px;
  }

  .trialHeader {
    display: flex;
    justify-content: space-between;
  }

  .container {
    padding: 0;
  }

  .imgBack {
    display: block;
  }

  .imgBackMob {
    display: none;
  }

  .txtWrapper {
    flex-basis: 50%;
    margin: 15px 84px 50px 0;
  }

  .titleHeader {
    max-width: 550px;
    margin-top: 0px;
    margin-bottom: 30px;
    font: var(--property-title-1);
  }

  .courseHeader {
    margin: 0;
  }

  .descriptionHeader {
    max-width: 600px;
    margin: 0;
    font-size: 20px;
    line-height: 30px;
  }

  .headerBtns {
    margin-top: 0;
  }

  .sectionHeader {
    font-size: 58px;
    line-height: 76px;
  }

  .startContainer {
    margin-top: 120px;
  }

  .switchToggleWrapper {
    margin-top: 55px;
  }

  .pageContainer {
    .cardWrapper {
      width: 350px;
      flex-shrink: 0;
      margin-right: 30px;

      &:nth-of-type(2n) {
        margin-right: 30px;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOut {
  to {
    transform: translate3d(0, 100%, 0);
  }
}