.title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.cardsWrap {
  display: flex;
  min-height: 340px;
  gap: 20px;
}

.card {
  min-width: 255px;
  background-position: 50% 100%;
  background-repeat: no-repeat;
  border-radius: 20px;
  outline: 1px solid #ebebeb;
}
.txt {
  width: 222px;
  margin: 0 auto;
  margin-top: 30px;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  b {
    font-weight: 600;
  }
}

.card1 {
  background-color: #e1dbcb;
  background-size: 226px;
}

.card2 {
  border: 1px solid #ebebeb;
  background-color: #ffffff;
  background-size: 211px;
}

.card3 {
  background-color: #75d3ff;
  background-position: 50% calc(100% - 20px);
  background-size: 215px;
}

.card4 {
  background-color: #535353;
  background-size: 211px;
  color: #ffffff;
}

.scrollWrap {
  :global {
    .simplebar-wrapper {
      min-height: 340px;
    }
    .simplebar-track.simplebar-horizontal {
      height: 6px;
      margin: 0;
    }
    .simplebar-track {
      overflow: initial;
      border-bottom: 2px solid #d9d9d9;
      border-radius: 0px;
    }
    .simplebar-scrollbar {
      top: 4px !important;
      height: 4px !important;
    }
  }
}

@media (min-width: 768px) {
  .title {
    margin-bottom: 60px;
    font-size: 34px;
  }
}

@media (min-width: 1280px) {
  .title {
    font-size: 58px;
  }
}
