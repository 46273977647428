.title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.subtitle {
  margin-bottom: 40px;
  font-size: 16px;
  text-align: center;
}

.btn {
  width: 100%;
  margin-top: 40px;
}

@media (min-width: 768px) {
  .title {
    margin-bottom: 60px;
    font-size: 34px;
  }
  .subtitle {
    margin-bottom: 60px;
    font-size: 20px;
  }
  .btn {
    max-width: 235px;
    margin: 0 auto;
    margin-top: 40px;
  }
}

@media (min-width: 1280px) {
  .title {
    font-size: 58px;
  }
  .subtitle {
    padding: 0 85px;
  }
}
