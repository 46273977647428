


.toggleButton {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 46px;
  align-items: center;
  justify-content: center;
  padding: 0 19px;
  border: 1px solid var(--color-primary-100);
  background-color: var(--color-primary-white);
  border-radius: var(--property-border-radius);
  color: var(--color-primary-100);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.28px;
  line-height: 19px;
  outline: none;
  transition: all 0.5s;

  &:hover {
    border-color: var(--color-primary-100);
    background-color: #def4ff;
  }
}

.toggleButtonAccented {
  background-color: var(--color-primary-100);
  color: var(--color-primary-white);

  &:hover {
    border-color: var(--color-primary-80);
    background-color: var(--color-primary-80);
  }
}

.toggleButtonActive {
  width: 50px;
  background-color: var(--color-primary-100);
  pointer-events: none;

  &:hover {
    background-color: var(--color-primary-100);
  }
}

.toggleButtonText {
  transition: all 0.5s;
}

.toggleButtonPlus {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;
}

.toggleButtonPlusLine {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--color-primary-100);
  border-radius: 2px;
  transition: all 0.3s;
}

.lineAccented {
  background-color: var(--color-primary-white);
}

.toggleButtonPlusLine2 {
  transform: rotate(90deg);
}

.toggleButtonPlusLine1Active {
  background-color: var(--color-primary-white);
  transform: translate3d(4px, 0, 0) rotate(-45deg);
}

.toggleButtonPlusLine2Active {
  width: 60%;
  background-color: var(--color-primary-white);
  transform: translate3d(-1px, 2px, 0) rotate(45deg);
}
