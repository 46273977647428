.lectursWrapper {
  margin-top: 30px;
  .scroll :global {
    .simplebar-track.simplebar-horizontal {
      .simplebar-scrollbar::before {
        height: 0;
      }
    }
    .simplebar-scrollbar {
      display: none !important;
    }
  }
}

.lectur {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: -38px;
  margin-bottom: 10px;
}

.skeleton {
  width: 142px !important;
  height: 142px !important;
  border-radius: 50%;
}
.avatar {
  overflow: hidden;
  width: 142px;
  height: 142px;
  box-sizing: content-box;
  border: 13px solid #f8f8f8;
  margin-bottom: 6px;
  border-radius: 50%;
}
.imgWrapper {
  height: 142px;
  background-color: #e1dbcb;
  border-radius: 50%;
}

.name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.info {
  color: var(--color-base-80);
  font-size: 12px;
  line-height: 17px;
}

@media (min-width: 768px) {
  .scroll {
    .scrollWrapper {
      display: flex;
      width: 95%;
      justify-content: center;
      padding: 0;
    }
  }
}

@media (min-width: 1280px) {
  .lectur {
    margin-right: -67px;
  }

  .imgWrapper {
    height: 238px;
  }
  .skeleton {
    width: 238px !important;
    height: 238px !important;
  }
  .avatar {
    width: 238px;
    height: 238px;
    border: 18px solid #f8f8f8;
  }

  .name {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 27px;
  }

  .info {
    font-size: 16px;
    line-height: 22px;
  }

  .scroll {
    .scrollWrapper {
      margin-top: 15px;
    }
  }
}
