.avatar {
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-primary-white);
  border-radius: 50%;
}

.initials {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-white);
  font-size: 20px;
}

.author {
  display: flex;
  align-items: center;
  color: var(--color-base-60);
  font-size: 14px;
  line-height: 19px;
}

.authorDark {
  color: var(--color-base-black);
}

.lecturWrapper {
  position: absolute;
  display: flex;
  width: 50px;
  height: 50px;
}

.avatarWrapper {
  position: relative;
  display: flex;
  height: 48px;
  flex-direction: row;
  flex-shrink: 0;
  margin-right: 15px;
}

@media (min-width: 576px) {
  .avatarWrapper {
    margin-right: 20px;
  }
}
