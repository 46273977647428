.container {
  margin-bottom: 60px;
}

.headerContainer {
  margin-bottom: 25px;
}

.btnWrapper {
  margin: 0 20px;
}

.btn {
  background: transparent;
}

.outlined {
  padding: 3px 16px;
  background: #E1DBCB;
  border-radius: 200px;
}

.grid {
  display: grid;
  margin-right: 20px;
  margin-left: 20px;
  gap: 20px;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
}

.row1,
.row2 {
  display: flex;
  flex-direction: column;
}

.row2 {
  display: none;
}

.visible {
  display: flex;
}

.block1 {
  width: 100%;
  min-width: 100%;
  margin-bottom: 20px;
}

.block3 {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .headerContainer {
    max-width: 690px;
    margin-bottom: 60px;
  }

  .container {
    padding-top: 0;
    margin-bottom: 60px;
  }

  .outlined {
    padding: 5px 15px;
  }

  .grid {
    display: grid;
    margin-right: 0;
    margin-left: 0;
    gap: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: 240px 240px 240px;
  }

  .row1,
  .row2 {
    width: 100%;
    min-width: 100%;
    flex-direction: row;
  }

  .row2 {
    display: flex;
  }

  .block1 {
    width: 255px;
    min-width: 255px;
    margin-right: 30px;
    margin-bottom: 0;
  }

  .block3 {
    width: 350px;
    min-width: 350px;
    margin-right: 30px;
    margin-bottom: 0;
  }
}

@media (min-width: 1280px) {
  .container {
    margin-bottom: 120px;
  }

  .headerContainer {
    max-width: 1110px;
  }

  .outlined {
    padding: 10px 20px;
  }

  .grid {
    grid-template-rows: 240px 240px;
  }

  .row3 {
    grid-column-end: 3;
    grid-column-start: 2;
    grid-row-end: 3;
    grid-row-start: 1;
  }

  .block2 {
    max-width: 510px;
  }

  .block4 {
    max-width: 415px;
  }

  .block5 {
    max-width: 285px;
  }
}

@media (min-width: 1280px) {
}
