.spacer {
  width: 100%;
  height: 60px;
}

@media (min-width: 768px) {
  .spacer {
    height: 120px;
  }
}
