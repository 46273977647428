.card {
  height: 100%;
  padding: 20px;
  padding-bottom: 30px;
  border: 1px solid var(--color-base-20);
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.iconWrapper {
  width: auto;
  max-width: fit-content;
  height: 46px;
}

.title {
  margin-top: 20px;
  font-size: 18px;
  line-height: 22px;
}

.text {
  margin-top: 10px;
  font-size: 14px;
  line-height: 19px;
}

@media (min-width: 768px) {
  .iconWrapper {
    height: 56px;
  }
}

@media (min-width: 1280px) {
  .card {
    padding: 30px;
  }

  .title {
    margin-top: 24px;
    font-size: 20px;
    line-height: 28px;
  }

  .text {
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
  }
}
