.badgesBlock {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  background: var(--color-primary-60);
  border-radius: 20px;
}

.badge {
  width: fit-content;
  padding: 8px 16px;
  border: 2px solid var(--color-primary-white);
  margin-bottom: 15px;
  border-radius: 50px;
  color: var(--color-primary-white);
  font-weight: 500;
  line-height: 19px;
  text-transform: uppercase;
}

.badge:last-child {
  margin-bottom: 0;
}

.badgeColored {
  background: var(--color-primary-white);
  color: var(--color-base-black);
}

.badgeLeft {
  margin-right: auto;
}

.badgeRight {
  margin-left: auto;
}

@media (min-width: 400px) {
  .badge {
    font-size: 18px;
  }
}

@media (min-width: 500px) {
  .badgesBlock {
    padding: 28px 112px;
  }
}

@media (min-width: 768px) {
  .badgesBlock {
    padding: 28px;
  }

  .badge {
    font-size: 14px;
  }

  .badgeLeft {
    margin-right: auto;
  }

  .badgeRight {
    margin-left: auto;
  }
}
