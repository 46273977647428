.title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
}

.edition {
  position: relative;
  padding: 20px;
  background-color: #535353;
  border-radius: 20px;
  color: var(--color-primary-white);
}

.img {
  position: absolute;
  bottom: 118px;
  left: 50%;
  display: block;
  width: 100%;
  max-width: 233px;
  height: auto;
  margin: auto;
  transform: translateX(-50%);
}

.imgBottom {
  bottom: 0;
}

.titleCard {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
}

.content {
  display: block;
}

.subtitleCard {
  margin-bottom: 166px;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
}

.subtitleAfterSub {
  margin-bottom: 30px;
}

.resultMsg {
  font-size: 16px;
  line-height: 22px;
  text-align: left;
}

.resultAfterSub {
  margin-bottom: 254px;
}

.emailInput {
  input {
    max-height: 46px;
  }
}

.formWrap {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: top;
  margin-bottom: 20px;
  gap: 20px;
  label p {
    display: none;
  }
}

.btnWrap {
  min-width: 182px;
}

.dopTxt {
  color: var(--color-base-40);
  font-size: 12px;
  a {
    color: var(--color-base-40);
    font-size: 12px;
    text-decoration: underline;
  }
}

@media (min-width: 768px) {
  .content {
    display: flex;
    min-height: 193px;
    flex-direction: column;
  }

  .title {
    margin-bottom: 60px;
    font-size: 34px;
  }
  .edition {
    padding: 30px;
  }
  .titleCard {
    margin-bottom: 20px;
    font-size: 34px;
    line-height: 46px;
    text-align: left;
  }
  .subtitleCard {
    margin-bottom: 40px;
    font-size: 20px;
  }

  .resultMsg {
    margin-top: auto;
  }

  .formWrap {
    max-width: 502px;
    flex-direction: row;
  }
  .img {
    display: none;
    max-width: auto;
  }
}

@media (min-width: 1280px) {
  .title {
    font-size: 58px;
    line-height: 80px;
  }
  .edition {
    padding: 40px 30px;
  }
  .titleCard {
    width: 50%;
  }
  .subtitleCard {
    max-width: 563px;
  }

  .emailInput {
    width: 300px;
  }

  .dopTxt {
    width: 50%;
  }
  .img {
    right: 89px;
    bottom: 0;
    left: unset;
    display: block;
    width: 375px;
    max-width: initial;
    height: 356px;
    transform: unset;
  }
}
