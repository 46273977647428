.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  gap: 10px;
}
.dotsWrap {
  display: flex;
  margin-bottom: 34px;
  gap: 6px;
}
.dots {
  position: relative;
  width: 50px;
  height: 6px;
  background-color: var(--color-base-20);
  border-radius: 7px;
}

.progress {
  position: absolute;
  top: 0px;
  left: 0;
  width: 0px;
  height: 100%;
  background-color: red;
  border-radius: 7px;
  transition: width 5000ms;
}

.progress.active {
  position: absolute;
  top: 0px;
  left: 0;
  width: 10px;
  height: 100%;
  background-color: red;
  border-radius: 7px;
  transition: width 4000ms;
}
.date {
  width: max-content;
  padding: 6px 12px;
  background-color: var(--color-grey-ultra-light);
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
}

.speciality {
  color: var(--color-grey-dark-2);
  font-size: 14px;
}
.leftSide {
  width: 100%;
  max-width: initial;
}
.rightSide {
  width: 100%;
  margin-bottom: 66px;
}
.rightSide img {
  width: 100%;
  height: auto;
  border-radius: 14px;
}
.title {
  max-width: initial;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
}

.lectur {
  display: flex;
  margin-bottom: 30px;
}

.btnWrap {
  position: absolute;
  bottom: 22px;
  left: 20px;
  width: calc(100% - 40px);
  max-width: 628px;
}

.card {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--color-base-20);
  background-color: var(--color-primary-white);
  border-radius: 20px;
}

@media (min-width: 768px) {
  .btnWrap {
    left: 30px;
  }
  .top {
    margin-top: 40px;
  }
}

@media (min-width: 768px) {
  .card {
    padding: 30px;
  }
  .btnWrap {
    bottom: 28px;
    left: 30px;
  }
  .title {
    font-size: 20px;
  }
}

@media (min-width: 1280px) {
  .card {
    flex-direction: row;
    align-items: flex-start;
  }
  .title {
    overflow: hidden;
    max-width: 440px;
    max-height: 80px;
  }
  .leftSide {
    width: 420px;
    padding-right: 34px;
  }
  .rightSide {
    width: auto;
    margin-bottom: 0px;
  }
  .btnWrap {
    bottom: 30px;
    left: 30px;
    width: 145px;
  }
}
