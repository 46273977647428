.eventCardWrapper {
  display: flex;
  width: max-content;
  padding: 0 20px;
  margin-top: 20px;
  margin-bottom: 14px;
}

.scrollAreaCards {
  height: 100%;
  max-height: 100%;
  padding-bottom: 5px; /* чтобы не появлялся сбоку скролл из-за подсказок чекбокса  */
}

.exit {
  opacity: 0;
  transform: scaleY(0.9);
  transition: all 0.5s 1.3s;
}

@media (min-width: 375px) {
  .eventCardWrapper {
    padding: 0 24px;
    padding-left: 12px;
  }
}

@media (min-width: 1024px) {
  .eventCardWrapper.onlyBigScreens {
    display: flex;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .eventCardWrapper {
    display: block;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media (min-width: 1280px) {
  .eventCardWrapper {
    width: 100%;
    padding: 0 40px;
  }

  .noMargin {
    margin: 0;
  }
  .noPadding {
    padding: 0;
  }
}

@media (min-width: 1500px) {
  .eventCardWrapper {
    padding: 0;
  }
}
