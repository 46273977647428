.title {
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

@media (min-width: 768px) {
  .title {
    margin-bottom: 60px;
    font-size: 34px;
  }
}

@media (min-width: 1280px) {
  .title {
    font-size: 58px;
  }
}
